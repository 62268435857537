.app {
    &-layout {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        min-height: 100dvh;
    }

    &-info {
        position: relative;
    
        &-content {
            padding: 180px 0px;
    
            @include media-breakpoint-down(sm) {
                padding: 160px 0px 80px;
            }
            
            h1 {
                color: $light-green-200;
                margin-bottom: 56px;
    
                @include media-breakpoint-down(sm) {
                    margin-bottom: 24px;
                    font-size: 24px;
                    line-height: 36px;
                }
            }

            h2 {
                font-size: 24px;
                line-height: 36px;

                @include media-breakpoint-down(sm) {
                    font-size: 20px;
                    line-height: 28px;
                }
            }
            
            p {
                margin: 24px 0px;
    
                @include media-breakpoint-down(sm) {
                    margin: 18px 0px;
                }
            }

            small {
                font-size: 14px;
                line-height: 24px;

                @include media-breakpoint-down(sm) {
                    font-size: 12px;
                    line-height: 18px;
                }
            }

            a {
                text-decoration: underline;
            }
        }
    }
}

.custom-scroll {
    overflow-y: auto;
    scroll-padding-right: 10px;  

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        // -webkit-appearance: none;
    }
    
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
        background: $gray-600;
        border-radius: 16px;
    }

    &::-webkit-scrollbar-thumb:hover {
        opacity: 0.8;
    }
}

.small-hero {
    position: relative;
    width: 100%;
    min-height: 100vh;
    height: auto;
    padding-top: 230px;
    background-color: $black-300;
    margin-bottom: -16px;
    
    @include media-breakpoint-down(sm) {
        padding-top: 184px;
    }

    &.cut {
        margin-bottom: 0px;
        border-radius: 0px 0px 16px 16px;
        background-color: transparent;

        &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            background-color: $black-300;
            z-index: -1;
            width: 100%;
            height: 850px;
            border-radius: 0px 0px 16px 16px;

            @include media-breakpoint-down(md) {
                height: 750px;
            }
        }
    }

    &.circle, &.circles {
        &::before {
            content: "";
            background: linear-gradient(179.97deg, rgba(255, 255, 255, 0) -18.04%, rgba(255, 255, 255, 0.04) 99.98%);
            position: absolute;
            right: -20px;
            top: 10%;
            width: 402px;
            height: 402px;
            border-radius: 50%;   
            z-index: 0;
        }
    }

    &.circles {
        &::after {
            content: "";
            width: 402px;
            height: 402px;
            border-radius: 50%;
            background: linear-gradient(179.97deg, rgba(255, 255, 255, 0) -18.04%, rgba(255, 255, 255, 0.14) 99.98%);
            position: absolute;
            left: -10%;
            bottom: 20%;
        }
    }

    .container {
        flex-direction: column;
    }

    &-wrap {
        width: 100%;
    }

    &-text {
        text-align: center;

        @include media-breakpoint-up(sm) {
            max-width: 60%;
            margin: 0 auto;
        }
        
        h1 {
            font-size: 56px;
            line-height: 62px;
            color: $white;
            font-weight: 600;

            @include media-breakpoint-down(sm) {
                font-size: 30px;
                line-height: 33px;
            }
        }
    
        p {
            color: $gray-500;
            font-size: 20px;
            font-weight: 400;
            line-height: 23px;
            margin-top: 25px;
        }
    }

}
.app-page {
    position: relative;

    &-hero {
        &-img {
            position: absolute;
            top: 0;
            width: 100%;
            height: auto;
            transform: rotate(-16deg) translate(22%, -5%);
            right: 0;

            @include media-breakpoint-down(sm) {
                width: 100vw;
                transform: rotate(-16deg) translate(12%, 10%);
            }
            
            @include media-breakpoint-down(xs) {
                width: 150vw;
                transform: rotate(-16deg) translate(62%, -50%);
            }
        }
    }

    .main-hero {
        padding-top: 260px;
        margin-bottom: 270px;

        @include media-breakpoint-down(lg) {
            margin-bottom: 100px;
        }

        @include media-breakpoint-down(md) {
            padding-top: 128px;
            margin-bottom: 300px;
        }

        @include media-breakpoint-down(sm) {
            margin-bottom: 96px;
        }

        &-wrap {
            align-items: flex-start;
        }

        &-content {
            p {
                max-width: 550px;
            }
        }

        &-img.circle {
            // width: 100%;
            top: 50%;
            position: relative;
            width: 100%;

            @include media-breakpoint-down(md) {
                top: 10%;
            }

            @include media-breakpoint-down(sm) {
                top: 0;
            }

            @include media-breakpoint-down(xs) {
                // position: absolute;
                top: 50vh;
            }

            &::after {
                width: 100vh;
                height: 100vh;
                right: 0;
                top: 0;
                transform: translate(40%, -40%);

                @include media-breakpoint-down(sm) {
                    width: 80vh;
                    height: 80vh;
                    right: -10vw;
                }
            }
        }
    }

    &-overview {
        position: relative;

        @include media-breakpoint-down(md) {
            margin-bottom: 96px;
        }

        h3 {
            font-size: 24px;
            line-height: 36px;
            font-weight: 600;
            color: $black-100;
        }

        .app-cards {
            top: -36px;

            @include media-breakpoint-down(md) {
                top: 0;
                margin-top: 32px;
            }

            &-item {
                &:first-child {
                    .app-cards-item-img {
                        @include media-breakpoint-down(md) {
                            width: 80%;
                            margin: 0 auto;
                            margin-top: 34px;
                        }

                        @include media-breakpoint-down(xs) {
                            width: 100%;
                            margin-top: 34px;
                        }
                    }
                }

                &:nth-child(2) {
                    .app-cards-item-img {
                        width: 90%;

                        @include media-breakpoint-down(md) {
                            width: 70%;
                            margin: 0 auto;
                            margin-top: 34px;
                        }

                        @include media-breakpoint-down(xs) {
                            width: 100%;
                        }
                    }
                }

                &:nth-child(3) {
                    .app-cards-item-img {
                        width: calc(100% + 52px * 2);
                        left: -52px;

                        @include media-breakpoint-down(md) {
                            left: -24px;
                            width: 100%;
                        }

                        @include media-breakpoint-down(xs) {
                            width: 110%;
                        }
                    }
                }
                &:nth-child(4) {
                    .app-cards-item-img {
                        width: 110%;
                        left: -10%;
                    }
                }
            }
        }
    }

    .app-block {
        .app-list-item {
            flex-direction: column;
            margin-bottom: 64px;

            @include media-breakpoint-down(sm) {
                margin-bottom: 32px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &-text {
                margin-left: 0;
                margin-top: 32px;

                @include media-breakpoint-down(sm) {
                    margin-top: 20px;
                }
            }
        }

        &-img {
            img {
                position: relative;
                z-index: 1;
            }
        }

        &.voice {
            @include media-breakpoint-down(sm) {
                margin-bottom: 64px;
                
                .app-block-img {
                    margin-bottom: 40px;
                }
            }

        }
        
        &.programming {
            padding-bottom: 120px;

            @include media-breakpoint-down(md) {
                padding-bottom: 0;
            }

            .app-block-img {
                position: relative;
                
                @include media-breakpoint-down(sm) {
                    max-width: 60%;
                }

                &::after {
                    content: "";
                    width: 660px;
                    height: 660px;
                    border-radius: 50%;
                    position: absolute;
                    top: 50px;
                    left: -220px;
                    background: linear-gradient(180deg, rgba(84, 47, 190, 0) 0%, rgba(84, 47, 190, 0.24) 100%);
                    opacity: 0.3;

                    @include media-breakpoint-down(lg) {
                        width: 50vw;
                        height: 50vw;
                        top: -10vw;
                        left: -20vw;
                    }
                }
            }
        }
    }

    &_whatsapp {
         .main-hero {
            &-wrap {
                align-items: center;

                @include media-breakpoint-down(lg) {
                    flex-direction: column;
                }
            }
        }
    }
}
.app-section.media {
    padding: 160px 0px 70px;

    @include media-breakpoint-down(sm) {
        padding: 100px 0px 20px;
    }

    .media__wrap {
        position: relative;
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        @include media-breakpoint-down(sm) {
            max-width: 440px;
        }

        @include media-breakpoint-down(xs) {
            max-width: initial;
            display: flex;
            justify-content: center;
        }

        img, video {
            width: 100%;
            height: auto;
        }

        video {
            @include media-breakpoint-down(xs) {
                width: auto;
                max-width: 140vw;
                margin-left: calc(-50vw + 50%);
                margin-right: calc(-50vw + 50%);
            }
        }
    }
}
.app-teleanalytixai {
    --container-max-width: 1536px;
    --container-padding-xl: 140px;

    main {
        .app-button {
            border-radius: 14px;
            font-size: 20px;
            font-weight: 500;
            line-height: 140%;
            max-width: 265px;
            width: 100%;
            height: auto;
            display: inline-flex;
            padding: 14px 24px;
    
            &-icon {
                width: 32px;
                height: 32px;
                order: -1;
                margin-left: 0;
                margin-right: 8px;
            }
    
            &:hover {
                .app-button-icon {
                    margin-left: 0;
                }
            }
    
            &.green {
                padding: 24px;
                font-size: 30px;
                font-weight: 600;
                color: map-get($teleanalytixai-colors, 'lime-950');
                border: 2px solid map-get($teleanalytixai-colors, 'green-300');                
            
                &:hover {
                    opacity: 1;
                    background-color: map-get($teleanalytixai-colors, 'green-400' );
                }

                &:active {
                    opacity: 0.7;
                }
            }
        }
    }

    .main-hero {
        overflow: hidden;
        min-height: 948px;
        height: auto;

        @include media-breakpoint-down(sm) {
            min-height: 100dvh;
            padding-top: 200px;
            padding-bottom: 270px;
            border-radius: 0px 0px 8px 8px;
        }

        &-bcg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
            }
        }

        &-content {
            margin: 0 auto;
            max-width: 810px;
            display: flex;
            flex-direction: column;
            align-items: center;

            * {
                text-align: center;
            }

            h1 {
                font-size: 54px;
                line-height: 140%;
                font-weight: 400;

                @include media-breakpoint-down(sm) {
                    font-size: 36px;
                }

                img {
                    vertical-align: middle;
                    height: auto;
                    max-width: 366px;

                    @include media-breakpoint-down(sm) {
                        max-width: 237px;
                    }
                }
            }

            p {
                max-width: initial;
                margin-top: 26px;
                font-size: 18px;
                line-height: 155%;
                font-weight: 300;
                color: map-get($teleanalytixai-colors, 'gray-a5');
            }

            .app-button {
                margin-top: 80px;
                max-width: 223px;

                @include media-breakpoint-down(sm) {
                    margin-top: 64px;
                }
            }
        }
    }
}
.app-section.faq {
    padding: 200px 0px;
    background-color: map-get($teleanalytixai-colors, 'gray-50' );
    position: relative;

    // Purple Hazes
    &::before, &::after {
        content: "";
        @include haze-effect();
    }

    &::before {
        @include media-breakpoint-up(sm) {
            left: 0;
            top: 35%;
            transform: translateX(-35%);
        }

        @include media-breakpoint-down(sm) {
            right: 0;
            top: 15%;
        }
    }

    &::after {
        right: 0;
        top: 55%;
        transform: translateX(35%);

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
    
    .faq {
        &__items {
            display: flex;
            flex-direction: column;
            position: relative;
            z-index: 1;
            
            @include media-breakpoint-up(lg) {
                padding: 0px 95px;
            }
        }

        &__item {
            background-color: $white;
            border-radius: 24px;
            border: 1px solid map-get($teleanalytixai-colors, 'gray-100' );
            display: flex;
            flex-direction: column;
            padding: 32px 48px;
            cursor: pointer;
            margin-top: 10px;

            // Space for a chevron
            padding-right: 88px;
            position: relative;
            overflow: hidden;
            outline: none;
            user-select: none;

            @include media-breakpoint-down(sm) {
                padding: 32px 24px;
                padding-right: 64px;                
            }

            &::after {
                content: "";
                display: block;
                width: 32px;
                height: 32px;
                background-image: url(../../../assets/icons/teleanalytix/chevron-in-circle-gray.svg);
                background-repeat: no-repeat;
                background-size: contain;
                position: absolute;
                top: 32px;
                right: 38px;
                pointer-events: none;
                transition: .3s;

                @include media-breakpoint-down(sm) {
                    right: 24px;
                }
            }

            &-question {
                h4 {
                    font-size: 16px;
                    line-height: 150%;
                    font-weight: 600;
                    color: map-get($teleanalytixai-colors, 'gray-800' );
                }
            }

            &-answer {
                height: 0;
                margin-top: 0;
                opacity: 0;
                transition: .3s;

                p {
                    color: map-get($teleanalytixai-colors, 'gray-800');
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 150%;
                }
            }

            &.faq__item_collapsed {
                &::after {
                    transform: scale(1, -1);
                }

                .faq__item-answer {
                    opacity: 1;
                    height: auto;
                    margin-top: 16px;
                }
            }
        }
    }
}

.app-section.black-rounded + .app-section.faq {
    padding-top: 380px;

    @include media-breakpoint-down(sm) {
        padding-top: 166px;
    }
}
.telecomanalytix-data-transformed {
    position: relative;
    padding-bottom: 188px;

    svg:first-of-type {
        z-index: 1;
    }

    .app-section {
        &__wrap {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
        }
    }


    // Archer points
    // archer poins in outgoing message
    .outgoing-archer-point {
        &_1 {
            width: 0px;
            height: 0px;
            position: absolute;
            margin: 0;
            left: 0;
            top: 50%;
            transform: translate(-50%, -50%);
            display: flex;
    
            img {
                width: 18px;
                height: 18px;
                position: relative;
                z-index: 1;
                top: -50%;
                left: -50%;
                transform: translate(-50%, -50%);
            }
        }

        &_2 {
            width: 0px;
            height: 0px;
            position: absolute;
            left: 25%;
            top: calc(46px + .75rem);
        
            @include media-breakpoint-down(lg) {
                left: 15%;
            }
    
            @include media-breakpoint-down(sm) {
                top: 28px;
                left: 0;
            }
    
            @include media-breakpoint-down(xs) {
                left: -8px;
            }
        }
        
        &_3 {
            position: absolute;
            left: 5%;
            bottom: 80%;
    
            @include media-breakpoint-down(sm) {
                left: -9px;
                bottom: auto;
                top: .75rem;
            }
        }   
    }

    .incoming-archer-point {
        
        &_1 {
            position: absolute;
            top: 20%;
            right: 2%;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
        
        &_2 {
            position: absolute;
            top: -100%;
            right: 2%;
            
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
        
        &_3 {
            position: absolute;
            width: 0;
            height: 0;
            right: calc(-32px - 24px);
            bottom: 20px;
            margin: 0;
    
            img {
                width: 40px;
                height: 40px;
                position: relative;
                z-index: 1;
                top: -20px;
                left: -68px;
            }
        }
    }

    .teleanalytix-chat {
        margin-top: -128px;
        position: relative;
        
        &__main {
            padding-top: 52px;

            @include media-breakpoint-down(sm) {
                padding-top: 30px;
            }
        }            

        &__message {
            position: relative;
            
            &-text {
                position: relative;                
            }
        }
    }

    h2 {
        position: relative;
        z-index: 1;
        color: map-get($teleanalytixai-colors, 'gray-800');
        font-size: 36px;
        line-height: 133%;
        font-weight: 700;
        text-align: center;
        margin-top: 160px;

        @include media-breakpoint-down(sm) {
            margin-top: 115px;
        }
    }

    &__process-img {
        margin-top: 80px;
        position: relative;
        max-width: 1000px;
        width: 100%; 
        
        @include media-breakpoint-down(sm) {
            margin-top: 54px;            
            max-width: initial;
            display: flex;
            justify-content: center;
        }

        img {
            width: 100%;
            height: auto;
            position: relative;
            
            @include media-breakpoint-down(sm) {
                max-width: 300px;
                margin: 0 auto;
            }
        }

        &::before {
            content: "";
            width: 850px;
            height: 700px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -35%);
            background-image: url(../../../assets/images/grid-large.svg);
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    &__info {
        max-width: 480px;
        margin: 0 auto;
        margin-top: 122px;
        display: flex;
        flex-direction: column;
        position: relative;

        & > * {
            margin: 8px 0px;
        }

        * {
            font-size: 18px;
            font-weight: 400;
            line-height: 180%;
            text-align: center;
        
            b, strong {
                font-weight: 700;
            }
        }
    }

    // Purple Hazes
    &::before, &::after {
        content: "";
        @include haze-effect();
    }

    &::before {
        left: 0;
        top: 30%;
        transform: translateX(-35%);

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &::after {
        right: 0;
        top: 50%;
        transform: translateX(35%);

        @include media-breakpoint-down(sm) {
            left: 50%;
            top: 75%;
            transform: translateX(-50%);
        }
    }
}
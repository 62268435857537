.app-signup {
    position: relative;
    
    .app-form {
        position: relative;
        z-index: 1;

        @include media-breakpoint-up(md) {
            max-height: 650px;
            padding-right: 0;
        }

        &-sidebar {
            @include media-breakpoint-up(sm) {
                padding: 33px 24px;
            }

            @include media-breakpoint-down(sm) {
                padding-bottom: 16px;
                padding-top: 36px;
            }

            &::before {
                content: "";
                position: absolute;
                width: 98px;
                height: 98px;
                border-radius: 50%;
                left: 80%;
                transform: translateX(-50%);
                bottom: 222px;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.24) 0%, rgba(84, 47, 190, 0.24) 100%);
                
                @include media-breakpoint-down(sm) {
                    left: auto;
                    right: 30px;
                    bottom: 186px;
                }
            }
        }

        &-main {
            padding: 24px 45px;
            // padding-bottom: 8px;
            overflow-y: auto;

            @include media-breakpoint-down(sm) {
                padding: 40px 16px 24px;
            }
        }
    }

    &-steps {
        display: flex;
        flex-direction: column;
        height: 100%;

        h3 {
            color: $white;
            font-size: 30px;
            line-height: 36px;
            letter-spacing: -0.025em;
            font-weight: 600;

            @include media-breakpoint-down(sm) {
                font-size: 24px;
            }
        }

        &-wrap {
            padding-right: 45px;
            margin-top: 48px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between; 
            position: relative;

            @include media-breakpoint-down(sm) {
                margin-bottom: 86px;
            }

            &-buttons {
                display: flex;
                justify-content: space-between;
            }
        }

        &-images {
            position: relative;
            margin-top: auto;

            @include media-breakpoint-down(md) {
                margin-left: 30%;
            }

            @include media-breakpoint-down(sm) {
                margin-left: 32px;
            }

            .first {
                position: relative;
                z-index: 1;

                @include media-breakpoint-down(sm) {
                    width: 70%;
                }
            }

            .second {
                position: absolute;
                bottom: 50%;
                left: 68%;

                @include media-breakpoint-down(md) {
                    left: 60%;
                }

                @include media-breakpoint-down(sm) {
                    left: auto;
                    right: 20px;
                    bottom: 31px;
                    width: 46px;
                }
            }

            .third {
                position: absolute;
                bottom: -10px;
                left: 51%;
                z-index: 1;

                @include media-breakpoint-down(md) {
                    left: 28%;
                }

                @include media-breakpoint-down(sm) {
                    left: 48%;
                }
            }

            .fourth {
                position: absolute;
                bottom: 100%;
                left: 10%;
                width: 70px;

                @include media-breakpoint-down(md) {
                    width: 40px;
                    left: 30%;
                }
            }
        }

        &-item {
            display: flex;
            align-items: flex-start;
            margin-bottom: 40px;
            position: relative;
            z-index: 1;

            &:nth-child(3) {
                display: none;
            }

            &::after {
                content: "";
                position: absolute;
                width: 2px;
                height: calc(100% + 32px);
                background-color: #D1D5DB;
                top: 32px;
                left: 15.5px;

                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }  

            &:last-child {

                &::after {
                    display: none;
                }

                margin-bottom: 0;
            }

            &.filled {
                .app-signup-steps-item {
                    &-dot {
                        background-image: url(../../assets/icons/check.svg);
                        background-repeat: no-repeat;
                        background-size: cover;

                        &::before {
                            display: none;
                        }
                    }
                }

                img {
                    display: flex;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    position: relative;
                    z-index: 2;
                }
            }

            &-dot {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                flex-shrink: 0;
                background-color: $white;
                border: 2px solid $gray-500;
                position: relative;
            }

            &-text {
                display: flex;
                flex-direction: column;
                margin-left: 16px;

                span {
                    font-size: 12px;
                    line-height: 16px;
                    color: $gray-500;
                    text-transform: uppercase;
                    letter-spacing: 0.025em;
                    font-weight: 600;
                }

                p {
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 400;
                    color: $gray-500;
                }
            }

            &.active {
                .app-signup-steps-item {
                    &-dot {
                        border: 2px solid $light-green;
                        
                        &::before {
                            content: "";
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                            background-color: $light-green;
                        }
                    }
                }
            }
        }
    }

    &-wrap {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        &-buttons {
            display: flex;
            justify-content: space-between;
            margin-top: auto;
            // padding-bottom: 24px;
        }

        h3 {
            font-size: 18px;
            line-height: 24px;
            font-weight: 500;
            color: $black-600;
            font-family: 'Inter', sans-serif;
            margin-bottom: 8px;
        }

        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $black-200;
            display: flex;
            align-items: center;

            img {
                margin-right: 4px;
            }
        }
    }

    &-fill {
        h3 {
            font-family: 'Poppins', sans-serif;
            color: $black-100;
        }

        &-enter {
            margin-top: 40px;
            margin-bottom: 64px;
            display: flex;
            flex-wrap: wrap;
            align-items: self-end;
            justify-content: space-between;

            @include media-breakpoint-down(sm) {
                margin-bottom: 32px;
            }
            
            &.company {
                padding-bottom: 16px;
                border-bottom: 2px solid #F6F7F8;
            }

            &.company {
                .app-input {
                    margin-bottom: 48px;

                    @include media-breakpoint-down(sm) {
                        margin-bottom: 32px;
                    }
                }
            }

            .app-input {
                margin-bottom: 32px;
            }
        }
    }

    &-gray-block {
        border-radius: 16px;
        background-color: $gray-100;
        width: 100%;
        height: 100%;
        max-height: 280px;
        margin-top: 32px;
        padding: 40px 32px;

        @include media-breakpoint-down(md) {
            margin-bottom: 32px;
        }

        @include media-breakpoint-down(sm) {
            padding: 32px 16px 24px;
        }
    }

    &-verify {
        &-phone {
            display: flex;
            flex-direction: column;
            height: 100%;

            &-wrap {
                p {
                    margin-bottom: 8px;
                }

                .app-input {
                    width: 55%;

                    @include media-breakpoint-down(sm) {
                        width: 100%;
                    }
                    
                    &-wrap {
                        border-bottom: none;
                    }

                    &-phone {
                        border-radius: 6px;
                        border: 1px solid $blue-100;
                        background-color: $white;
                    
                        .form-control {
                            height: 48px;
                        }
                    }
                }

                .app-button {
                    border-radius: 8px;
                    margin: 0 auto;
                    margin-top: 68px;

                    @include media-breakpoint-down(sm) {
                        width: 100%;
                        margin-top: 24px;
                    }
                }
            }
        }

        &-code {
            display: flex;
            flex-direction: column;
            height: 100%;

            &-wrap {
                
                h4 {
                    font-size: 20px;
                    color: #000;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: center;

                    @include media-breakpoint-down(sm) {
                        font-size: 16px;
                    }
                }

                p {
                    font-size: 16px;
                    line-height: 24px;
                    text-align: center;
                    color: $black-600;
                    justify-content: center;
                    font-weight: 400;

                    @include media-breakpoint-down(sm) {
                        font-size: 14px;
                    }

                    a {
                        color: $purple;
                        text-decoration: underline;
                        margin-left: 6px;
                    }
                }
            }

            &-inputs {
                display: flex;
                justify-content: center;
                margin-top: 40px;
                margin-bottom: 24px;

                input {
                    width: 58px;
                    height: 72px;
                    background-color: #fff;
                    box-sizing: border-box;
                    border: none;
                    text-align: center;
                    border-radius: 8px;
                    padding: 12 16px;
                    margin: 0 8px;
                    font-size: 40px;
                    
                    &::placeholder {
                        color: $blue-300;
                    }
                }
            }
        }
    }

    &-confirm {
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;

        @include media-breakpoint-down(md) {
            min-height: 60vh;
        }

        &-wrap {
            width: 100%;
            margin: 0 auto;
            margin-top: 96px;

            @include media-breakpoint-down(sm) {
                margin-top: 48px;
            }

            p {
                justify-content: center;
                font-size: 20px;
                line-height: 22px;
                font-weight: 500;
                color: $black-200;
                text-align: center;
            }
        }

        &-img {
            width: 100%;
            max-width: 317px;
            margin: 0 auto;
            margin-top: 32px;

            @include media-breakpoint-down(sm) {
                max-width: 204px;
                margin-top: 32px;
                margin-bottom: 70px;
            }

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    .app-button {
        &.next {
            @include media-breakpoint-up(md) {
                margin-left: auto;
            }
        }
    }
}
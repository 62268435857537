.app-home {
    position: relative;

    .main-hero {
        &-img {
            display: flex;
            justify-content: flex-end;
        }
    }

    &-hero {
        &-img {
            position: relative;
            width: 45vw;
            pointer-events: none;
            user-select: none;

            img {
                pointer-events: none;
                user-select: none;
            }

            @include media-breakpoint-down(md) {
                width: 65vw;
            }

            @include media-breakpoint-down(sm) {
                width: calc(100vw - 16px);
            }
            
            &-wrap {
                position: relative;
                top: 0;
                right: 0;
                z-index: 2;
                overflow: hidden;
                
                &.second {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;

                    img {
                        position: absolute;
                        top: 0;
                        height: 100%;
                        right: 0;
                    }

                }
            }
        }

        &-drag {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
            -webkit-appearance: none;
            appearance: none;
            background: transparent;
            outline: none;
            margin: 0;
            cursor: pointer;

            &::-webkit-slider-runnable-track {
                height: 100%;
            }

            &::-webkit-slider-thumb {
                background: $light-green;
                border-radius: 4px;
                position: relative;
                appearance: none;
                -webkit-appearance: none;
                opacity: 1;
                cursor: pointer;
                width: 4px;
                height: 100%;
                z-index: 3;
            }

            &::-moz-range-thumb {
                width: 4px;
                background: $light-green;
                cursor: pointer;
                height: 100%;
              }

            &-line {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 2;
                pointer-events: none;

                span {
                    cursor: pointer;
                    position: absolute;
                    width: 66px;
                    height: 66px;
                    left: 50%;
                    pointer-events: none;
                    bottom: 50%;
                    transform: translateY(50%);
                    margin-left: -33px;
                    z-index: 3;
                    
                    img {
                        user-select: none;
                        pointer-events: none;
                    }
    
                    &::before, &::after {
                        border-radius: 50%;
                        border: 2px solid $light-green;
                        content: "";
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform-origin: center;
                        transform: translate(-50%, -50%);
                        z-index: 1;
                        opacity: 1;
                        height: 28px;
                        width: 28px;
                        animation: 3s ease 0s infinite normal none running;
                    }
                    
                    &::after {
                        animation-delay: 0.5s;
                    }

                    &.animated {
                        &::before, &::after {
                            animation-name: pulse;
                        } 
                    }
    
                    @keyframes pulse {
                        80% {
                            width: 84px;
                            height: 84px;
                            opacity: 0;
                        }
                        
                        100% {
                            width: 28px;
                            height: 28px;
                            opacity: 0;
                        }
                    }
                }
            }
            
        }

        &-companies {
            position: relative;
            margin-top: 80px;
            width: 100%;

            @include media-breakpoint-down(md) {
                margin-top: 48px;
            }

            &-item {
                @include media-breakpoint-down(md) {
                    width: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 42px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            
            &-wrap {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 24px;
                padding: 24px 57px;
                border-radius: 8px;
                border: 2px solid rgba(243, 244, 246, 0.2);

                @include media-breakpoint-down(md) {
                    flex-wrap: wrap;
                    justify-content: center;
                    padding: 32px 0px;
                }

                img {
                    pointer-events: none;
                    user-select: none;

                    @include media-breakpoint-down(sm) {
                        height: 100%;
                        max-height: 42px;
                        padding: 0px 7px;
                        width: auto;
                    }
                }
            }

            h3 {
                text-align: center;
                color: $white;
                font-size: 32px;
                line-height: 48px;
                font-weight: 400;
                letter-spacing: 0.025em;

                @include media-breakpoint-down(md) {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }

    &-description {
        margin-bottom: 80px;
        max-width: 50%;

        @include media-breakpoint-down(md) {
            max-width: initial;
            margin-bottom: 40px;
        }

        h2 {
            font-size: 56px;
            font-weight: 400;
            color: $black-100;
            line-height: 62px;
            margin-bottom: 24px;

            @include media-breakpoint-down(md) {
                font-size: 30px;
                line-height: 33px;
            }

            span {
                font-weight: 600;
            }
            
        }

        p {
            margin-bottom: 32px;
        }
    }

    &-cloud {
        position: relative;
        margin-top: 120px;
        width: 100%;

        @include media-breakpoint-down(md) {
            margin-top: 96px;
        }
        
        .container {
            @include media-breakpoint-down(lg) {
                flex-direction: column;
            }
        }

        &-img {
            max-width: 50%;
            width: 100%;
            position: relative;
            margin-left: -64px;
            
            @include media-breakpoint-down(lg) {
                margin: 0 auto;
                margin-bottom: 40px;
                max-width: 80%;
            }

            @include media-breakpoint-down(md) {
                margin-bottom: 80px;
            }

            @include media-breakpoint-down(sm) {
                margin-bottom: 40px;
            }

            img {
                position: relative;
                z-index: 1;
                width: 100%;
            }

            &::after {
                content: "";
                position: absolute;
                width: 30vw;
                height: 30vw;
                max-width: 490px;
                max-height: 490px;
                left: 0px;
                top: 9.5px;
                background: linear-gradient(180deg, rgba(84, 47, 190, 0) 0%, rgba(84, 47, 190, 0.24) 100%);
                opacity: 0.3;
                border-radius: 50%;

                @include media-breakpoint-down(lg) {
                    width: 50vw;
                    height: 50vw;
                }
            }

            &::before {
                content: "";
                position: absolute;
                width: 50px;
                height: 50px;
                right: 0;
                top: 16px;
                border-radius: 50%;
                background: linear-gradient(180deg, rgba(84, 47, 190, 0) 0%, rgba(84, 47, 190, 0.24) 100%);
                opacity: 0.3;
            }
        }
    }

    &-platform {
        margin-top: 160px;

        @include media-breakpoint-down(md) {
            margin-top: 96px;
        }

        &-item {
            padding: 40px;
            margin-bottom: 32px;
            background-color: $gray-200;
            border-radius: 6px;
            height: auto;
            position: relative;
            box-sizing: border-box;
            min-height: 296px;

            @include media-breakpoint-down(sm) {
                margin-bottom: 24px;
                padding: 24px;
                
                &:last-child {
                    margin-bottom: 0;
                }
            }

            @include media-breakpoint-down(md) {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            &-text {
                max-width: 50%;

                @include media-breakpoint-down(md) {
                    max-width: initial;
                }

                h4 {
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 36px;
                    color: $black-100;

                    @include media-breakpoint-down(sm) {
                        font-size: 20px;
                        line-height: 30px;
                    }
                }

                p {
                    font-weight: 400;
                    margin-top: 16px;
                    font-size: 16px;
                    line-height: 24px;
                    color: $black-200;

                    @include media-breakpoint-down(sm) {
                        font-size: 14px;
                        line-height: 21px;
                        margin-top: 10px;
                    }
                }
            }

            &-img {
                position: absolute;
                right: -30px;
                top: -30px;
                height: 110%;

                @include media-breakpoint-down(lg) {
                    height: 100%;
                    top: -40px;
                    right: -40px;
                }

                @include media-breakpoint-down(md) {
                    position: relative;
                    top: 0;
                    right: 0;
                    width: 90%;
                    top: 24px;
                }

                @include media-breakpoint-down(sm) {
                    width: 100%;
                }

                img {
                    height: 100%;
                    width: auto;

                    @include media-breakpoint-down(md) {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }

    .app-tag {
        margin-bottom: 40px;
    }
}
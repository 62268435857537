$sides: (
    'top': 't',
    'left': 'l',
    'bottom': 'b',
    'right': 'r'
);

@each $side, $letter in $sides {
    @for $px  from 0 through 30 {
        .m#{$letter}-#{$px * 5} {
            margin-#{$side}: #{$px * 5}px !important; 
        }

        .p#{$letter}-#{$px * 5} {
            padding-#{$side}: #{$px * 5}px !important; 
        }
        
        .#{$letter}-#{$px * 5} {
            #{$side}: #{$px * 5}px !important; 
        }
    }
}
.app-tag {
    background-color: $light-green;
    border-radius: 4px;
    text-align: center;
    width: 144px;
    padding: 6px 0px;

    @include media-breakpoint-down(md) {
        margin-bottom: 40px;
    }
    
    span {
        font-size: 15px;
        font-weight: 500;
        line-height: 21px;
        white-space: nowrap;
        color: $black-100;

        @include media-breakpoint-down(sm) {
            font-size: 14px;
        }
    }
}
$light-green: #DBFA60;
$light-green-100: rgba(219, 250, 96, 0.33);
$light-green-200: #C5E156;

$purple: #542FBE;

$purple-100: #F0EDF9;
$purple-200: #531EA3;

$white: #fff;
$white-transparent: rgba(255, 255, 255, .4);

$gray-100: #F6F7F8;
$gray-200: #FBFBFB;
$gray-300: #F4F5F7;
$gray-400: #F6F7F8;
$gray-500: #D1D5DB;
$gray-600: #BBC0C8;
$gray-700: #F9FAFB;

$black-100: #262D3A;
$black-200: #3A4459;
$black-300: #1C1C1C;
$black-400: #4B5563;
$black-500: #6B7280;
$black-600: #111827;
$black-700: #200E32;
$black-800: #08090C;

$blue: #828C9F;
$blue-100: #D9E2EC;
$blue-300: #E2E8F0;

$red: #B60E03;

$teleanalytixai-colors: (
    'purple-50' : #f5f4fe,
    'purple-100' : #eceafd,
    'purple-200': #dbd9fb,
    'purple-300': #BFBAF8,
    'purple-400': #9F93F2,
    'purple-600': #6B46E1,
    'purple-700': $purple,
    'purple-950': #26165f,

    'gray-a5': #A5A0B5,
    'gray-50': #F8FBFF,
    'gray-100': #F1F5F9,
    'gray-200':#E2E8F0,
    'gray-300': #CBD5E1,
    'gray-400': #94A3B8,
    'gray-500': #64748B,
    'gray-700': #3A4459,
    'gray-800': #262D3A,
    'gray-900': #171B23,
    'green-100': #F3FDCB,
    'green-300': #DBFA60,
    'green-400': #C5F02B,
    
    'lime-50': #FBFFE6,
    'lime-950': #213003
);
.app-home-slider {
    position: relative;
    margin-top: 134px;
    margin-bottom: 60px;
    // overflow-x: hidden;

    // @include media-breakpoint-down(sm) {
    //     display: none;
    // }

    h2 {
        font-size: 32px;
        line-height: 48px;
        font-weight: 600;
        color: $black-100;
        text-align: center;
        margin: 0 auto;

        @include media-breakpoint-down(sm) {
            font-size: 28px;
            line-height: 42px;
        }

        @include media-breakpoint-down(xs) {
            font-size: 24px;
            line-height: 33px;
        }
    }

    &-wrap {
        // display: flex;
        // align-items: stretch;
        margin-top: 48px;
    }

    &-item {
        // width: 424px;
        display: flex !important;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        border-radius: 16px;
        height: auto;
        padding: 55px 34px 55px 50px;
        background-color: $gray-200;

        @include media-breakpoint-down(sm) {
            padding: 36px 24px 36px 36px;
        }

        &-logo {
            height: 48px;            
            width: 48px;
            flex-shrink: 0;
            border-radius: 50%;
            overflow: hidden;
            margin-bottom: 24px;
            margin-right: 12px;
            
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        &-head {
            position: relative;
            margin-bottom: 30px;
            display: flex;


            h3 {
                font-size: 24px;
                overflow: hidden;
                -webkit-line-clamp: 1;
                display: -webkit-box;
                -webkit-box-orient: vertical;
            }
        }

        &-text {
            position: relative;

            img {
                position: absolute;
                top: -10px;
                left: -10px;
            }
            
            p {
                position: relative;
                z-index: 1;
                font-size: 14px;
                font-weight: 500;
                line-height: 28px;
                color: $black-300;
                overflow: hidden;
                -webkit-line-clamp: 4;
                display: -webkit-box;
                -webkit-box-orient: vertical;
            }
        }

        &-person {
            margin-top: 36px;
            display: flex;
            
            &-avatar {
                width: 52px;
                height: 52px;
                overflow: hidden;
                border-radius: 50%;
                margin-right: 16px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &-text {
            
                h6, span {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: $black-300;
                }
                
                h6 {
                    opacity: .9;
                }
            }
        }
    }

    &-loader {
        position: relative;
        width: 100%;
        margin-top: 180px; 
    }

    .slick {
        &-slide {
            width: 30vw;
            max-width: 500px;
            margin-right: 24px;
            height: auto;
            
            &:nth-child(3n) {
                .app-home-slider-item {
                    background-color: $purple-100;
                }
            }

            &:nth-child(3n + 1) {
                .app-home-slider-item {
                    background-color: $light-green-100;
                }
            }

            & > div:first-child {
                display: flex;
                height: 100%;
            }

            @include media-breakpoint-down(md) {
                width: 40vw;
                max-width: initial;
            }

            @include media-breakpoint-down(sm) {
                width: 70vw;
                margin-right: 16px;
            }

            @include media-breakpoint-down(xs) {
                width: 80vw;
            }
        }

        &-list {
            width: 120vw;
        }

        &-slider {
            width: 100%;
        }

        &-track {
            display: none;
        }

        &-initialized .slick-track {
            display: flex;
        }

        &-dots {
            position: relative;
            bottom: 0;
            width: 100%;
            display: flex;
            margin-top: 48px;

            @include media-breakpoint-down(sm) {
                margin-top: 36px;
            }

            li {
                
                &.slick-active {
                    button {
                        background: $purple;
                    }
                }

                button {
                    width: 16px;
                    height: 16px;
                    background-color: #dbdbdb;
                    margin-right: 16px;
                    border-radius: 50%;

                    &::before {
                        content: '';
                        display: none;
                    }
                }
            }
        }
    }
}
.top-keyfeatures {
    position: relative;
    padding: 180px 0px 120px;
    background-color: map-get($teleanalytixai-colors, 'gray-50' );
    overflow: hidden;

    @include media-breakpoint-down(sm) {
        padding: 100px 0px;
    }

    // Grid effects
    @include media-breakpoint-up(sm) {
        &::before {
            content: "";
            @include grid-effect();
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(-30%, -30%);
        }
    }

    .container {
        position: relative;
    }

    &__title {
        width: 100%;
        max-width: 336px;

        @include media-breakpoint-up(lg) {
            max-width: 40%;
            padding-left: 40px;
        }    
        
        img {
            width: 100%;
            height: auto;
        }
    }

    &__items {
        width: 100%;
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 120px 0px;

        @include media-breakpoint-down(md) {
            padding: 60px 0px;
        }

        @include media-breakpoint-down(sm) {
            padding: 40px 0px;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
        }

        &:first-child {
            @include media-breakpoint-up(sm) {
                padding-top: 0;

                .top-keyfeatures__item-content::before {
                    content: "";
                    @include grid-effect();
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    display: block;
                    transform: translateX(-25%);
                }
            }

        }

        &-content {
            display: flex;
            flex-direction: column;
            position: relative;
            z-index: 1;
            max-width: 33.3333%;
            flex-basis: 33.333%;
            padding-left: 40px;
            
            @include media-breakpoint-down(lg) {
                flex-basis: 60%;
                max-width: 60%;
                padding-left: 0px;
            }
            
            @include media-breakpoint-down(sm) {
                flex-basis: 80%;
                max-width: 80%;
            }

            @include media-breakpoint-down(xs) {
                max-width: 100%;
                flex-basis: 100%;
            }

            &::after {
                content: "";
                @include grid-effect();
                position: absolute;
                top: 0;
                left: 0;
                transform: translateX(-25%);
                display: block;
                z-index: -1;

                @include media-breakpoint-down(sm) {
                    left: 50%;
                    top: 10%;
                    transform: translate(-50%, -50%);
                }
            }
        }

        &-icon {
            width: 70px;
            height: 70px;
            pointer-events: none;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        h3 {
            margin-top: 24px;
            color: map-get($teleanalytixai-colors, 'purple-950' );
            font-size: 24px;
            line-height: 133%;
            font-weight: 600;
        }

        p {
            margin-top: 10px;
            font-size: 16px;
            line-height: 155%;
            color: map-get($teleanalytixai-colors, 'gray-900' );
        }

        &-img {
            position: relative;
            pointer-events: none;
            flex-basis: 100%;
            width: 100%;
            display: flex;
            justify-content: center;

            @include media-breakpoint-up(sm) {
                margin-right: calc(0px - var(--container-padding-lg));
                flex-basis: 66.666%;
            }

            img {
                width: 100%;
                height: auto;

                @include media-breakpoint-down(xs) {
                    width: 200vw;
                }
            }

            &-archer {
                position: absolute;
                left: 13%;
                top: 47%;
                height: 100%;
                width: auto;

                @include media-breakpoint-down(sm) {
                    display: none;
                }

                img {
                    height: 100%;
                    width: auto;
                }
            }
        }
    }
}
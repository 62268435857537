.app-button {
    width: 168px;
    height: 42px;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 9px 0px;
    font-family: 'Poppins', sans-serif;
    transition: .3s;
    border: 2px solid $black-100;
    background-color: transparent;
    color: $black-100;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    
    &.green {
        border: none;
        color: $black-100;
        background-color: $light-green;
    }

    &.light {
        border: 1px solid $white;
        color: $white;
    }

    &.without-border {
        border: none;
        color: $black-400;
        
        img {
            display: none;
        }
    }

    &.without-arrow {
        .app-button-icon {
            display: none;
        }
    }

    &.dark {
        background-color: $black-100;
        color: $white;
    }

    // &.purple {}
    &.purple-gradient {
        color: $white;
        border: 1px solid rgba(84, 47, 190, 0.50);
        background: linear-gradient(188deg, $purple -22.12%, map-get($teleanalytixai-colors, 'purple-950') 93.1%);
    
        &:hover {
            opacity: 1;
            background: linear-gradient(188deg, $purple 21.45%, map-get($teleanalytixai-colors, 'purple-950') 93.1%)
        }

        &:active {
            background-color: $purple;
            opacity: 0.8;
        }
    }

    &.hidden {
        opacity: 0;
        visibility: hidden;
    }

    &:hover {
        opacity: .7;

        .app-button-icon {
            margin-left: 14px;
        }
    }

    &:disabled {
        opacity: .7;
        cursor: not-allowed;
    }

    &-icon {
        width: 24px;
        height: 24px;
        margin-left: 8px;
        transition: .3s;

        img {
            width: 100%;
            height: auto;
        }
    }
}